html,

body {
  margin: 0;
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #080808;
  color: #fff;
  overflow-x: hidden;
}
#root {
  min-height: 100vh;
  display: flex;
}

h1 {
  font-size: 5rem;
}
@media screen and (max-width: 768px) {
  h1 {
    font-size: 3rem;
  }
}

.unsupported-network {
  width: 340px;
  max-width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin: auto;
  padding: 1rem;
}


.button-style {
  display: inline-block;
  padding: 10px 20px;
  text-decoration: none;
  color: white;
  background-color: #007bff;
  border-radius: 15px; /* Increased border-radius for rounder corners */
  font-size: 16px;
}

.button-style:hover {
  background-color: #0056b3;
}

.welcome-text {
  margin: 10 auto;
  padding: 20px;
  max-width: 2400px; /* Adjust based on your preference */
  text-align: justify;
}

.content {
  margin: 10 auto;
  padding: 20px;
  max-width: 2400px; /* Adjust this to control the width */
  text-align: center; /* Center the text */
  font-size: 20px; /* Larger text size for emphasis */
}

.tagline {
  font-family: Arial, sans-serif;
  color: #3b3838;
  font-size: 20px;
}

.tableHeader {
  background-color: #0e0f0e;
  color: white;
}

.forestRow {
  background-color: #98d014;
}

.realEstateRow {
  background-color: #171718;
}

.oceansRow {
  background-color: #E0F7FA;
}

.co2LevelsRow {
  background-color: #FFFDE7;
}

.methaneRow {
  background-color: #F3E5F5;
}

.countriesRow {
  background-color: #0b0b0b;
}

.continentsRow {
  background-color: #ECEFF1;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  border: 1px solid black;
}

.table th {
  padding: 8px;
  text-align: left;
}



.error {
  width: 340px;
  max-width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin: auto;
  padding: 1rem;
  border-radius: 1rem;
  background-color: #f00;
}

.landing,
.connect-wallet,
.mint-nft,
.member-page {
  flex-direction: column;
  width: 960px;
  max-width: calc(100% - 1rem);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin: auto;
  align-content: center;
  display: flex;
  text-align: center;
}

.member-page > div {
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: left;
  gap: 2rem;
}
.member-page > div > div {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 1rem;
}
@media screen and (max-width: 768px) {
  .member-page > div {
    flex-direction: column;
  }
  .member-page > div > div {
    width: 100%;
  }
}

.member-page form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.member-page form > div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.member-page form h5 {
  margin-top: 0;
  color: #7700ee;
}

.member-page form .card > div {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.member-page form small {
  text-align: center;
}

.card {
  background-color: #fff;
  padding: 1rem;
  border-radius: 1rem;
  color: #000;
  box-shadow: 3.1px 6.2px 6.2px hsl(0deg 0% 0% / 0.4);
}

.btn-hero {
  margin: auto;
  width: 250px;
}

button {
  cursor: pointer;
  background-color: #e80b0b;
  color: #fff;
  border: none;
  font-weight: bold;
  font-family: inherit;
  padding: 1.2rem 2rem;
  text-transform: uppercase;
  border-radius: 3rem;
  font-size: 1.2rem;
}

button:hover {
  background-color: #ef0c0c;
}

button:focus {
  background-color: #eb0b0b;
}

button:disabled {
  opacity: 0.8;
  pointer-events: none;
}
