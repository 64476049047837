/* App.css */

/* General table styling */
.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

/* Row coloring for different sections */
.tableHeader, .comparisonTable thead tr {
  background-color: #3f453f;
  color: white;
}

.forestRow { background-color: #09095e; }
.agricultureRow { background-color: #177741; }
.climateRow { background-color: #1b2973; }
.naturalCatastrophesRow { background-color: #420e20; }
.realEstateRow { background-color: #957575; }
.oceansRow { background-color: #045964; }
.co2LevelsRow { background-color: #4a4404; }
.methaneRow { background-color: #480652; }
.countriesRow { background-color: #502b27; }
.continentsRow { background-color: #0b88dc; }

/* Presentation styles */
.insurBankPresentation {
  color: #fefdfd;
}

.presentationTitle { color: #00ffe1; margin-bottom: 20px; }
.sectionTitle { color: #28a745; font-weight: bold; }

.insurBankPresentation p { margin-bottom: 15px; }
.insurBankPresentation strong { color: #754dd2; }

/* Comparison table specific */
.comparisonTable th, .comparisonTable td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #080808;
}

.comparisonTable th {
  background-color: #007bff;
  color: white;
  border-bottom: 2px solid #0056b3;
}

.comparisonTable tr:nth-child(even) { background-color: #322e2e; }
.comparisonTable tr:nth-child(odd) { background-color: #ac3e45; }
.comparisonTable tr:hover { background-color: #222d38; }
.comparisonTable tr:last-child td { border-bottom: none; }

/* Partners and navigation layout */
.partners-container, .fixed-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
}

.partner img, .image-link img, .video-container iframe {
  height: 150px; /* Uniform height for images and video */
  width: auto; /* Maintain aspect ratio */
  object-fit: contain;
}

nav {
  position: fixed; /* Fixed navigation */
  top: 0;
  left: 0;
  padding: 10px;
  z-index: 1000; /* Ensure navigation is above other content */
}

nav ul { list-style-type: none; margin: 0; padding: 0; }
nav a { color: #007bff; text-decoration: none; }

/* Ensure the fixed container is directly in the viewport and not inside any scrollable div */
.fixed-container {
  position: fixed; /* This makes the container stay in place during scroll */
  top: 20vh; /* Adjusts the vertical position; vh is relative to the viewport height */
  left: 50%; /* Centers the container horizontally */
  transform: translateX(-50%); /* Centers the container with respect to its width */
  display: flex; /* Lays out children (image and video) in a row */
  align-items: center; /* Centers children vertically */
  justify-content: space-around; /* Spreads children out evenly */
  z-index: 10; /* Ensures the container is above other content */
}

/* Style for the image link and video to have similar sizes */
.fixed-container .image-link img, .fixed-container .video-container iframe {
  width: 200px; /* Adjust width as necessary */
  height: 200px; /* Adjust height as necessary */
  object-fit: cover; /* Ensures the content covers the area of the element */
}

.videos-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.video {
  margin: 0 ; /* Adds 15px space on the left and right of each video */
  width: 460px; /* Adjust width as needed */
  height: 215px; /* Adjust height as needed */
}


.video iframe {
  width: 100%; /* Makes the video iframe responsive to the container's width */
  height: 315px; /* Set a fixed height or adjust as needed */
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px; /* Adjust the space between the elements */
}


.btn-hero-unique {

  flex: 1; /* This makes sure that the button and video container take up equal space */
  
}

.btn-hero img, .video-container iframe {
  width: 0%; /* Make image and video responsive */
  max-height: 215px; /* Adjust based on your preference */
  object-fit: cover; /* This ensures that the aspect ratio is maintained */
}



.content-new {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px; /* Adjust space between elements as needed */
}

.btn-hero-new, .video-container-new {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 50%; /* Each takes up half of the .content-new width */
  max-width: 150px; /* Maximum width of children */
}

.btn-hero-new img, .video-container-new iframe {
  width: 100%; /* Full width of the container for smaller screens */
  height: auto; /* Adjust height automatically to maintain aspect ratio */
  object-fit: cover; /* For images, ensures coverage potentially cropping */
}

@media (max-width: 320px) { /* Adjust the max-width as needed */
  .content-new {
    flex-direction: column;
  }
  .btn-hero-new, .video-container-new {
    max-width: none; /* Removes the max-width restriction on small screens */
  }
}